import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderLocationConfiguration } from './work-order-location.configuration';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator, CtModelType } from '@ctsolution/ct-framework';
import { Router } from '@angular/router';
import { LocationRouteData } from 'projects/ct-manager/src/app/_core/route-data/location.route-data';

@Component({
  selector: 'ctm-work-order-location',
  templateUrl: './work-order-location.component.html',
  styleUrls: ['./work-order-location.component.scss']
})
export class WorkOrderLocationComponent {

  @Input() configuration: WorkOrderLocationConfiguration | null = null;
  modelConfiguration: CtModelConfiguration<any> | null = null;

  constructor(private router: Router) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(LocationRouteData().setModelType(CtModelType.DATATABLE))
      .setOnDetail(location => {

        this.router.navigate(["location","box","detail",location.Oid]);

      })
      .setAutoSetupBreadcrumbs(false);

    configuration
      .initialized()
      .subscribe(() => configuration?.setOperations([CtModelOperator.Detail]));

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("WorkOrder.Oid")
          .setValue(this.configuration?.WorkOrder?.Oid)
          .setOperatorType(CtBinaryOperator.Equal))

    this.modelConfiguration = configuration;

  }

}
