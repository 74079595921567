 <div *ngIf="configuration">

  <h4 style="margin-left: 20px;">{{"SingleWorkOrder" | translate}}</h4>

  <ct-model  [configuration]="configuration"></ct-model>

</div>

<div *ngIf="workOrderList.length > 0" class="work-order-list">
  <mat-card *ngFor="let workOrder of workOrderList" class="work-order-card">
    <mat-card-header>
      <mat-card-title>{{ workOrder.Name }}</mat-card-title>
      <mat-card-subtitle><strong>{{"Code"|translate}}:</strong> {{ workOrder.Code }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p *ngIf="workOrder.Address"><strong>{{"Address" | translate}}:</strong> {{ workOrder.Address }}</p>
      <p *ngIf="workOrder.CompanyVAT"><strong>{{"COMPANYVAT" | translate}}:</strong> {{ workOrder.CompanyVAT }}</p>
      <p *ngIf="workOrder.Description"><strong>{{"Description" | translate}}:</strong> {{ workOrder.Description }}</p>
    </mat-card-content>
    <mat-card-actions>
      <ct-button [configuration]="buttonConfiguration"></ct-button>
    </mat-card-actions>
  </mat-card>
</div>
