import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderLocationComponent } from './work-order-location.component';
import { CtModelModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [WorkOrderLocationComponent],
  exports : [WorkOrderLocationComponent]
})
export class WorkOrderLocationModule { }
