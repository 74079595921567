import { Injectable } from "@angular/core";
import { EventTypeEnum, SchedulerEventPath } from "./scheduler-event-button/scheduler-event-button.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CtModelConfiguration, CtSchedulerEvent, CtSchedulerEventFormComponent, CtSchedulerEventFormConfiguration } from "@ctsolution/ct-framework";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class SchedulerService{

  constructor(
    private dialog: MatLegacyDialog,
    private router : Router,
    private route : ActivatedRoute
  ) {
  }

  createEventFromPath(modelConfiguration : CtModelConfiguration<any>, param?: SchedulerEventPath | null){

    const configuration = CtSchedulerEventFormConfiguration
    .create()
    .setModelConfiguration(modelConfiguration)

    if(param?.Oid && param?.Type)
    {
      const event = CtSchedulerEvent.create("",new Date(),new Date())
      event.Origin = +param?.Type;
      event.ExternalOid = param?.Oid;

      configuration.setEventValue(event);
    }

  return this.dialog
    .open(CtSchedulerEventFormComponent, {disableClose: true, maxWidth: 500, data: configuration})
    .afterClosed().toPromise();

  }

  navigateToPath(path : SchedulerEventPath){

    const queryParams = {
      TypeExtra: path.Type,
      OidExtra: path.Oid
    };

    // Naviga verso il nuovo path mantenendo eventuali altri queryParams esistenti
    this.router.navigate(['scheduler'],{queryParamsHandling: 'merge',queryParams}
    );


  }


  returnParam(id : number | null = null){

    const pId = this.route.snapshot.paramMap.get("id");
    let param : SchedulerEventPath | null = null;
    const currentUrl = this.router.url;
    let type : EventTypeEnum | null = null;

    if (currentUrl.includes('lead')) {
      type = EventTypeEnum.LEAD;
    } else if (currentUrl.includes('work-order')) {
      type = EventTypeEnum.WORKORDER;
    }


    if(id)
         param = SchedulerEventPath.create(id,type);
    else
    if(pId)
         param = SchedulerEventPath.create(+pId,type);

    return param

  }

}
