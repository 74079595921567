import { CTModelDatatableFilter } from "@ctsolution/ct-framework";
import { SchedulerEventPath } from "../scheduler-event-button/scheduler-event-button.component";

export class SchedulerListConfiguration {

  constructor(public Filter : CTModelDatatableFilter | null = null,public param : SchedulerEventPath | null = null,public Action: ((event: any) => void) | null = null){}

  setAction(value: ((event: any) => void) | null = null): SchedulerListConfiguration {

    this.Action = value;
    return this;

  }

}
