import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CTDynamicFormGeneratorComponent,
  CTModelDatatableFilter,
  CtBinaryOperator,
  CtDatatableContainerFilterValues,
  CtDynamicFormGeneratorConfiguration,
  CtModelConfiguration,
  CtModelDatatableOperators,
  CtModelRouteData,
  CtModelService,
  CtModelType, CtControlTypes, CtFormArrayControlOptions,
  CtSelectControlValue
} from '@ctsolution/ct-framework';
import { FormTemplateRouteData } from '../../../_core/route-data/form-template.route-data';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { FormModuleDataRouteData } from '../../../_core/route-data/form-module-data.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { FormModuleData } from '../../../_core/classes/form-module-data';
import { FormGroup } from '@angular/forms';
import { StatusFormModuleData } from '../../../_core/enum/status-form-module-data';
import { FormModuleDynamicParameter } from '../../../_core/classes/form-module-dynamic-parameter';
import { CTMGeneralService } from '../../../_core/lib/general.service';

@Component({
  selector: 'app-form-module-data-edit',
  templateUrl: './form-module-data-edit.component.html',
})
export class FormModuleDataEditComponent implements AfterViewInit {

  @ViewChildren('formGenerator') formGeneratorComponents: QueryList<CTDynamicFormGeneratorComponent> | null = null;

  datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  formConfigurations: Array<CtDynamicFormGeneratorConfiguration> = [];
  configuration: CtModelConfiguration<FormModuleDataEditComponent> | null = null;

  dataSource: FormModuleData | null = null;
  routeData: CtModelRouteData = FormModuleDataRouteData();

  FormModuleDataConfiguration = (): CtModelConfiguration<any> => CtModelConfiguration
    .create()
    .setRouteData(this.routeData);

  constructor(private route: ActivatedRoute, private modelService: CtModelService<any>, private router: Router, private generalService: CTMGeneralService) {
  }

  async ngAfterViewInit(): Promise<void> {

    await this.setup()

  }

  setup(): Promise<void> {


    this.route.queryParams.subscribe(params => {
      const q = params['q'];
      if (q) {

        const filterValues = JSON.parse(q);
        this.datatableContainerFilterValues = CtDatatableContainerFilterValues.create(filterValues);

      }

      this.configuration = CtModelConfiguration
        .create<FormModuleDataEditComponent>()
        .setRouteData(
          FormModuleDataRouteData()
            .setModelType(CtModelType.FORM))
        .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())

    });

    return new Promise<void>((resolve, reject) => {

      this.getFormModuleDataDynamicForms(this.getValueFilterCodeFormTemplate())
        .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<any>>>) => {

          if (!response.Result.DataSource) {

            reject(new Error('Empty data source'));
            return;

          }

          (<Array<any>>response.Result.DataSource ?? [])
            .forEach(async (cnfg, idx) => {

              const dynamicFormConfiguration = CtDynamicFormGeneratorConfiguration
                .create()
                .setFormGeneratorModel(cnfg);

              const pId: string | null = this.route.snapshot.paramMap.get("id");

              if (pId) {

                this.routeData.setId(+pId);

                this.modelService
                  .getInfos(this.FormModuleDataConfiguration())
                  .subscribe({
                    next: async (response) => {

                      this.dataSource = FormModuleData.create(response?.Result?.DataSource);

                      if (this.dataSource) {

                        const parsedValue = JSON.parse(this.dataSource.JSONContent ?? '')
                        setTimeout(() => this.setChildFormData(idx, parsedValue));

                        if (this.dataSource.Status == StatusFormModuleData.Validato) dynamicFormConfiguration.setDisableAll(true);

                      }

                      dynamicFormConfiguration
                        .setOnSubmit((filteredValue: any) => {

                          const parameter: FormModuleDynamicParameter = this.generateFormModuleDynamicParameter({
                            value: filteredValue.value,
                            formGeneratorOid: dynamicFormConfiguration?.formGeneratorModel?.Oid ?? -1
                          });

                          if (pId) {

                            parameter
                              .setOid(+pId)

                          }

                          this.insertFormModuleData(parameter);

                        });


                      this.formConfigurations
                        .push(dynamicFormConfiguration);

                    },
                    error: (err) => console.error('Errore durante il recupero delle informazioni:', err)
                  })


              } else {  //TODO: devo usare else perché senno la subscrive della datasource non fa in tempo, da rivedere

                dynamicFormConfiguration
                  .setOnSubmit((filteredValue: any) => {

                    const parameter: FormModuleDynamicParameter = this.generateFormModuleDynamicParameter({
                      value: filteredValue.value,
                      formGeneratorOid: dynamicFormConfiguration?.formGeneratorModel?.Oid ?? -1
                    });

                    if (pId) {

                      parameter
                        .setOid(+pId)

                    }

                    this.insertFormModuleData(parameter);

                  });


                this.formConfigurations
                  .push(dynamicFormConfiguration);

              }

            });

          resolve();

        });

    });

  }


  getValueFilterCodeFormTemplate(): string | null {

    let value: string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field === 'FormTemplate.Code')?.Value

    }

    return value

  }

  generateFormModuleDynamicParameter(params: { value: any, formGeneratorOid: number }) {

    return FormModuleDynamicParameter
      .create()
      .setJSONContent(JSON.stringify(params.value))
      .setFormGeneratorOid(params.formGeneratorOid)

  }

  getFormModuleDataDynamicForms(valueCodeFilter: string | null) {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(FormTemplateRouteData());

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Type')
          .setValue(2)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    if (valueCodeFilter) {

      const filter = CTModelDatatableFilter.create()
        .setField('Code')
        .setValue(valueCodeFilter)
        .setOperatorType(CtBinaryOperator.Equal);
      filters.Filters.push(filter);

    }

    return this.modelService
      .getList(configuration, filters)

  }

  private setChildFormData(idx: number, value: any) {

    const references = this.formGeneratorComponents?.toArray() ?? [];

    const componentRef = references[idx];

    if (componentRef) {

      componentRef
        .form
        .patchValue(value);

      componentRef
        .formConfiguration
        ?.controls
        ?.filter(items => items.type === CtControlTypes.FORMARRAY)
        .forEach(formArray => {

          const options = formArray.options as CtFormArrayControlOptions;

          if (!options) return;

          options
            .notifyValuesUpdated(value[formArray.name!]);

        })

      componentRef
        .formConfiguration
        ?.controls
        ?.filter(items => items.type === CtControlTypes.LOOKUP)
        .forEach(lookup => {

          const valueLookup = value[lookup.name!]

          this.generalService.getList(
            {
              controller: 'WorkOrder',
              filters: [
                CTModelDatatableFilter
                  .create()
                  .setField('Oid')
                  .setValue(valueLookup)
                  .setOperatorType(CtBinaryOperator.Equal)
              ]
            }
          ).subscribe((response: CtWebapiGenericResponse<any>) => {

            lookup
              .setValue(
                CtSelectControlValue
                  .create()
                  .setLabel(response.Result.DataSource[0].Name)
                  .setValue(valueLookup))

          })

        })

    }

  }

  getFormModuleDataValue(formGeneratorOid: number): Promise<FormModuleDynamicParameter | null> {

    return new Promise((resolve, reject) => {

      this.getFormuleModuleData(formGeneratorOid)
        .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<FormModuleDynamicParameter>>>) => {

          const result = (<FormModuleDynamicParameter[]>response.Result.DataSource ?? []);

          if (result.length) {

            const dataSource: FormModuleDynamicParameter = result[0];
            resolve(dataSource);

          }

          resolve(null);

        },
          (error) => reject(error)
        );
    });

  }

  getFormuleModuleData(formGeneratorOid: number) {

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('FormTemplate.Oid')
          .setValue(formGeneratorOid)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    return this.modelService
      .getList(this.FormModuleDataConfiguration(), filters);

  }

  insertFormModuleData(parameter: FormModuleDynamicParameter) {

    const FormModuleConfiguration = this.FormModuleDataConfiguration();

    if (parameter.Oid) {

      FormModuleConfiguration
        .RouteData
        ?.setId(parameter.Oid);

    }

    if (this.dataSource) {

      parameter.setStatus(this.dataSource.Status);

    }

    return this.modelService
      .putInfos(FormModuleConfiguration, parameter)
      ?.subscribe({
        next: (response: CtWebapiGenericResponse<number | null>) => {

          const commands = ['/', 'form-module-data'];

          if (response.Result && !isNaN(response?.Result)) {

            commands
              .push(
                'edit',
                response.Result?.toString());

            this.router.navigate(commands, { queryParamsHandling: 'preserve' });

          } else
            window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

        },
        error: (err) => console.error('Errore durante l\'operazione:', err)
      });

  }

  submit(formGroup: FormGroup | null) {

    if (!formGroup) return;

    formGroup.markAllAsTouched();

    if (formGroup.valid) {

      const value: StatusFormModuleData = formGroup.get(['Status'])?.value;

      if (value) {

        this.dataSource?.setStatus(value);

      }

      this.dataSource?.setFiles(null); //serve lato api senno schianta se ripasso quello che mi passano loro

      this.modelService
        .putInfos(this.FormModuleDataConfiguration(), this.dataSource)
        ?.subscribe({
          next: (response: CtWebapiGenericResponse<number | null>) => {

            const commands = ['/', 'form-module-data'];

            if (response.Result && !isNaN(response?.Result)) {

              commands
                .push(
                  'edit',
                  response.Result?.toString());

              this.router.navigate(commands, { queryParamsHandling: 'preserve' });

            } else
              window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

          },
          error: (err) => console.error('Errore durante l\'operazione:', err)
        });
    }

  }
}
